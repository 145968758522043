export const requiredValidator = (value: string): string | true => {
    if (value.length === 0)
        return "Il campo è richiesto";

    return true;
}

export const textOnlyValidator = (value: string): string | true => {
    if (!/^[a-zA-Z]+$/.test(value)) {
        return "Il campo deve contenere solo lettere";
    }

    return true;
}

export const fiscalCodeValidator = (value: string): string | true => {
    value = value.toUpperCase();
    const pattern = /^[A-Z]{6}\d{2}[ABCDEHLMPRST]{1}\d{2}[A-Z]\d{3}[A-Z]$/;

    if (value.length !== 16 || !/^[a-zA-Z0-9]+$/.test(value) || !pattern.test(value)) {
        return "Il Codice Fiscale inserito non è valido";
    }

    return true;
}

export const phoneNumberValidator = (value: string): string | true => {
    if (value.length < 7 || value.length > 12 || !/^\d+$/.test(value) || /^0+$/.test(value)) {
        return "Il numero di telefono inserito non è valido";
    }

    return true;
}

export const prefixValidator = (value: string): string | true => {
    if (!/^\+\d+$/.test(value)) {
        return "Il prefisso inserito non è valido";
    }

    return true;
}