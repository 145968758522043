import './SendOTP.scss';
import Header from "../../components/header/Header";
import { Button, Input } from 'smed-ui-react';
import { useState } from 'react';

const SendOTP = () => {
    const [fiscalCode, setFiscalCode] = useState("");
    const [phone, setPhone] = useState("");
    const [prefix, setPrefix] = useState("+39");

    const [fiscalCodeError, setFiscalCodeError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [prefixError, setPrefixError] = useState(false);

    const handleFiscalCodeChange = (e: any) => {
        setFiscalCode(e.target.value);
    }

    const handlePhoneChange = (e: any) => {
        setPhone(e.target.value)
    }

    const handlePrefixChange = (e: any) => {
        setPrefix(e.target.value)
    }

    const handleSubmit = () => {
        if (fiscalCodeError || phoneError || prefixError)
            return;
    }

    return (
        <section className="send-otp">
            <Header />
        </section>
    )
}

export default SendOTP;