import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ShowConsensi.scss';
import { Consenso } from '../../types'; // Import the Consenso type
const ShowConsensi: React.FC = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [consensi, setConsensi] = useState<Consenso[]>([]); // Use the Consenso type
    const [filteredConsensi, setFilteredConsensi] = useState<Consenso[]>([]); // Use the Consenso type
    const [error, setError] = useState('');
    const [search, setSearch] = useState('');
    const handleLogin = () => {
        if (username === 'VisualizazzioneConsensi1!' && password === '$Smed1!') {
            setLoggedIn(true);
            fetchConsensiData();
        } else {
            setError('Invalid credentials');
        }
    };
    const fetchConsensiData = () => {
        axios.get('https://consenso.smeditaly.it/api/fetchConsensi.php')
            .then(response => {
                setConsensi(response.data);
                setFilteredConsensi(response.data);
                const sortedData = response.data.sort((a: Consenso, b: Consenso) => b.id - a.id);
                setConsensi(sortedData);
                setFilteredConsensi(sortedData);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    };
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        if (e.target.value === '') {
            setFilteredConsensi(consensi);
        } else {
            setFilteredConsensi(
                consensi.filter(consenso =>
                    consenso.codice_fiscale.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    consenso.nome.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    consenso.cognome.toLowerCase().includes(e.target.value.toLowerCase())
                )
            );
        }
    };
    return (
        <div className="container">
            {!loggedIn ? (
                <div className="login-form">
                    <h2>Login</h2>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button onClick={handleLogin}>Login</button>
                    {error && <p className="error">{error}</p>}
                </div>
            ) : (
                <div className="consensi-list">
                    <h2>Consensi List</h2>
                    <input
                        type="text"
                        placeholder="Search by Codice Fiscale, Nome, or Cognome"
                        value={search}
                        onChange={handleSearch}
                        className="search-bar"
                    />
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Cognome</th>
                                <th>Codice Fiscale</th>
                                <th>Timestamp Accettazione</th>
                                {/* Add other headers as necessary */}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredConsensi.map((consenso: Consenso, index: number) => (
                                <tr key={index}>
                                    <td>{consenso.id}</td>
                                    <td>{consenso.nome}</td>
                                    <td>{consenso.cognome}</td>
                                    <td>{consenso.codice_fiscale}</td>
                                    <td>{consenso.timestamp_accettazione}</td>
                                    {/* Add other data cells as necessary */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};
export default ShowConsensi;