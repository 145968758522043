import "./Header.scss";
import { AgencyLogo } from "smed-ui-react";

const Header = () => {
    return (
        <header>
            <AgencyLogo primaryColor="#ffffff" />
        </header>
    );
}

export default Header;