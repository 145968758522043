import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Login from "./login/Login";
import AcceptTerms, { AcceptStep1, AcceptStep2, AcceptStep3 } from "./accept-terms/AcceptTerms";
import RegisterUser from "./register-user/RegisterUser";
import SendOTP from "./send-otp/SendOTP";
import ShowConsensi from "./show-consensi/ShowConsensi";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "",
                children: [
                    {
                        path: 'login',
                        element: <Login />
                    },
                    {
                        // Pagina visualizzata dal paziente per accettare termini e condizioni
                        path: 'acceptTerms/:token',
                        element: <AcceptTerms />,
                        children: [
                            {
                                path: 'step1',
                                element: <AcceptStep1 />
                            },
                            {
                                path: 'step2',
                                element: <AcceptStep2 />
                            },
                            {
                                path: 'step3',
                                element: <AcceptStep3 />
                            }
                        ]
                    },
                    {
                        path: 'registerUser',
                        element: <RegisterUser /> // Pagina visualizzata dall'infermiere per registrare un nuovo paziente, eg. caso FTP 
                    },
                    {
                        path: 'sendOtp',
                        element: <SendOTP /> // Pagina visualizzata dall'infermiere per inviare un SMS al paziente, eg. caso accettazione in autonomia
                    },
                    {
                        path: 'showConsensi',
                        element: <ShowConsensi />
                    }
                ]
            },
        ],
    },
]);

export default router;