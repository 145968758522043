import './AcceptTerms.scss';
import { Button, CheckBox, FloatingActionButton, Input, OneTimePassword } from "smed-ui-react";
import Header from "../../components/header/Header";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { fiscalCodeValidator, phoneNumberValidator, prefixValidator, requiredValidator, textOnlyValidator } from '../../common/common';
import Cookies from "js-cookie";

const AcceptTerms = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    Cookies.set("token", token ?? '');

    useEffect(() => {
        navigate("step1", { replace: true });
    }, []);

    return (
        <section className="accept-terms">
            <Header />
            <Outlet />
        </section>
    );
}

interface Tab {
    onNextTab?: () => void,
    onPreviousTab?: () => void,
}

export const AcceptStep1 = (props: Tab) => {
    const ref = useRef<HTMLDivElement>(null);
    const pageRef = useRef<HTMLDivElement>(null);
    const [canSwitchTab, setCanSwitchTab] = useState(false);
    const navigate = useNavigate();
    const token = Cookies.get("token");

    useEffect(() => {
        if (!ref.current || !pageRef.current)
            return;

        const sendUserData = async () => {
            const screenResolution = `${window.screen.width}x${window.screen.height}`;
            const browserInfo = navigator.userAgent;
            const referrer = document.referrer;

            await axios.post('https://consenso.smeditaly.it/api/storeUserData.php', { screenResolution, browserInfo, referrer });
        }

        const initialize = async () => {
            try {
                const response = await axios.get('https://consenso.smeditaly.it/api/downloadPrivacyPolicy.php', {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

                addDocumentContent(response.data.privacy_policy.contenuto);
            } catch (error) {
                console.log(error);
            }
        }

        const addDocumentContent = (content: string) => {
            if (!ref.current)
                return;

            // Resetta il contenuto del documento
            ref.current.innerHTML = "";

            // Creo un parser per ottenere gli elementi HTML
            const parser = new DOMParser();
            const document = parser.parseFromString(content, "text/html");

            // Ottengo i figli principali del documento (<article />)
            const articles = document.querySelectorAll("article");

            // Distinguiamo la logica sui diversi schermi
            if (window.innerWidth > 1024) {
                // Visualizzo il contenuto senza applicare nessuna modifica
                articles.forEach((article) => {
                    ref.current?.appendChild(article)
                })
            } else {
                // Visualizzo il contenuto senza applicare nessuna modifica
                articles.forEach((article) => {
                    ref.current?.appendChild(article)
                })
            }
        }

        const onScrollListener = () => {
            if (!pageRef.current)
                return;

            const scrollHeight = pageRef.current.scrollHeight;
            const scrollTop = pageRef.current.scrollTop;
            const clientHeight = pageRef.current.clientHeight;

            if (scrollTop + clientHeight > scrollHeight - 100) {
                setCanSwitchTab(true);
            } else {
                setCanSwitchTab(false);
            }
        }

        sendUserData();
        initialize();

        pageRef.current.addEventListener("scroll", onScrollListener);

        return () => {
            pageRef.current?.removeEventListener("scroll", onScrollListener);
        }
    }, []);

    const handleScrollDownClick = () => {
        if (!pageRef.current)
            return;

        const clientHeight = pageRef.current.clientHeight * .8;
        pageRef.current.scrollBy({ top: clientHeight, behavior: "smooth" });
    }

    const handleNextClick = () => {
        navigate("../step2");
    }

    return (
        <div ref={pageRef} className='tab privacy-policy'>
            <div ref={ref} className='document'></div>
            <div className={['fab-container', !canSwitchTab ? "visible" : ''].join(' ')}>
                <FloatingActionButton icon={"fa-solid fa-arrow-down"} onClick={handleScrollDownClick}></FloatingActionButton>
            </div>
            <div className={['button-container', canSwitchTab ? "visible" : ''].join(' ')}>
                <Button onClick={handleNextClick}>Prosegui</Button>
            </div>
        </div>
    );
}

export const AcceptStep2 = (props: Tab) => {
    const navigate = useNavigate();

    const location = useLocation();
    const { phone, fiscalCode, firstName, lastName } = location.state || {};

    const token = Cookies.get("token");

    const firstNameRef = useRef<any>(null);
    const lastNameRef = useRef<any>(null);
    const fiscalCodeRef = useRef<any>(null);
    const phoneRef = useRef<any>(null);
    const prefixRef = useRef<any>(null);
    const acceptPrivacyRef = useRef<any>(null);
    const acceptCommaARef = useRef<any>(null);
    const acceptCommaBRef = useRef<any>(null);
    const acceptAllegato5Ref = useRef<any>(null);

    const handleSendOTP = async () => {
        if (!firstNameRef.current || !lastNameRef.current || !fiscalCodeRef.current || !phoneRef.current || !prefixRef.current || !acceptPrivacyRef.current) {
            window.alert("Per favore, inserisci tutti i campi");
            return;
        }

        if (!firstNameRef.current.isValid() || !lastNameRef.current.isValid() || !fiscalCodeRef.current.isValid() || !phoneRef.current.isValid() || !prefixRef.current.isValid()) {
            return;
        }

        if (!acceptPrivacyRef.current.value()) {
            window.alert("Per completare l'accettazione del Consenso Informato, accetta accettare la Privacy Policy");
            return;
        }

        if (!acceptAllegato5Ref.current.value()) {
            window.alert("Per completare l'accettazione del Consenso Informato, accetta la Liberatoria di Responsabilità");
            return;
        }

        if (!acceptCommaARef.current.value()) {
            window.alert("Per completare l'accettazione del Consenso Informato, accetta le Finalità di Servizio al Punto 2 Comma A");
            return;
        }

        try {
            const fiscalCode = fiscalCodeRef.current.value().toUpperCase();
            const firstName = firstNameRef.current.value();
            const lastName = lastNameRef.current.value();
            const acceptCommaA = acceptCommaARef.current.value();
            const acceptCommaB = acceptCommaBRef.current.value();

            let response = await axios.post('https://consenso.smeditaly.it/api/checkAcceptedTerms.php', { fiscalCode: fiscalCode }, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            })

            if (!response.data.accepted) {
                const fullPhoneNumber = prefixRef.current.value() + phoneRef.current.value();

                response = await axios.post('https://consenso.smeditaly.it/api/sendOtp.php', { fiscalCode, phone: fullPhoneNumber }, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                })

                navigate("../step3", { state: { phone: fullPhoneNumber, fiscalCode: fiscalCode, firstName, lastName, acceptCommaA, acceptCommaB, expiresAt: response.data.expiresAt } });
            } else {
                console.log("Privacy Policy già accettata");
                window.alert("Privacy Policy già accettata. Prova con un altro codice fiscale.");
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className="tab user-info">
            <div className="title">
                <h5 className="text_h5">Inserisci i tuoi dati</h5>
                <p className="text_color_secondary">Per procedere con l'accettazione del Consenso Informato, compila il form qui sotto.<br />Ti invieremo un codice OTP di verifica per la validazione.</p>
            </div>
            <div className="row">
                <Input
                    ref={firstNameRef}
                    className='first-name-input'
                    type="text"
                    placeholder="Nome"
                    value={fiscalCode ?? ""}
                    validator={[requiredValidator, textOnlyValidator]} />
            </div>
            <div className="row">
                <Input
                    ref={lastNameRef}
                    className='last-name-input'
                    type="text"
                    placeholder="Cognome"
                    value={fiscalCode ?? ""}
                    validator={[requiredValidator, textOnlyValidator]} />
            </div>
            <div className="row">
                <Input
                    ref={fiscalCodeRef}
                    className='fiscal-code-input'
                    type="text"
                    placeholder="Codice Fiscale"
                    value={fiscalCode ?? ""}
                    validator={[requiredValidator, fiscalCodeValidator]} />
            </div>
            <div className="row">
                <Input
                    ref={prefixRef}
                    type="text"
                    placeholder="Prefisso"
                    value="+39"
                    validator={[requiredValidator, prefixValidator]} />
                <Input
                    ref={phoneRef}
                    style={{ flex: 2 }}
                    type="text"
                    placeholder="Numero di telefono"
                    validator={[requiredValidator, phoneNumberValidator]} />
            </div>
            <div className="row">
                <div className="checkbox-list-item">
                    <CheckBox ref={acceptPrivacyRef} />
                    <p className="text_caption">Dichiaro di aver preso visione e di accettare privacy policy</p>
                </div>
            </div>
            <div className="row">
                <div className="checkbox-list-item">
                    <CheckBox ref={acceptAllegato5Ref} />
                    <p className="text_caption">Dichiaro di accettare la Liberatoria di Responsabilità per la richiesta di "Rinnovo certificato medico sportivo non agonistico"</p>
                </div>
            </div>
            <div className="row">
                <div className="checkbox-list-item">
                    <CheckBox ref={acceptCommaARef} />
                    <p className="text_caption">Dichiaro di accettare le Finalità di Servizio indicate al punto 2 comma A</p>
                </div>
            </div>
            <div className="row">
                <div className="checkbox-list-item">
                    <CheckBox ref={acceptCommaBRef} />
                    <p className="text_caption">Dichiaro di accettare le Finalità di Marketing indicate al punto 2 comma B</p>
                </div>
            </div>
            <div className="row" style={{ marginTop: "auto" }}>
                <Button onClick={handleSendOTP}>Invia codice di verifica</Button>
            </div>
        </div>
    );
}

export const AcceptStep3 = (props: Tab) => {
    const now = new Date();
    const location = useLocation();
    const { phone, fiscalCode, firstName, lastName, acceptCommaA, acceptCommaB, expiresAt } = location.state || {};
    const [remainingTime, setRemainingTime] = useState<number>(expiresAt - Math.round(now.getTime() / 1000));
    const [success, setSuccess] = useState(false);
    const [failed, setFailed] = useState(false);
    const navigate = useNavigate();

    const otpRef = useRef<any>(null);
    const token = Cookies.get("token");

    const handleAcceptTerms = async () => {
        if (!otpRef.current)
            return;

        if (!otpRef.current.isValid()) {
            window.alert("Per favore, inserisci il codice di verifica");
            return;
        }

        const otp = otpRef.current.toString();

        try {
            await axios.post('https://consenso.smeditaly.it/api/validateOtp.php', { phone: phone, otp: otp }, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            });

            await axios.post("https://consenso.smeditaly.it/api/acceptTerms2.php", { fiscalCode, firstName, lastName, acceptCommaA, acceptCommaB }, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                }
            });

            setSuccess(true);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeData = () => {
        navigate("../step2", { replace: true, state: { phone, fiscalCode, firstName, lastName, acceptCommaA, acceptCommaB } });
    }

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (remainingTime !== 0) {
                setRemainingTime(prevTime => prevTime - 1);
            } else {
                if (!failed)
                    setFailed(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    if (failed) {
        return (
            <div className="tab user-info">
                <div className="title">
                    <h5 className="text_h5">Tempo scaduto</h5>
                    <p className="text_color_secondary">Clicca sul pulsante qui sotto per tornare indietro e inviare nuovamente un codice di verifica OTP.</p>
                </div>
                <div className="row" style={{ marginTop: "auto" }}>
                    <Button onClick={handleChangeData}>Torna indietro</Button>
                </div>
            </div>
        );
    }

    const handleToSMEDNavigation = () => {
        window.location.href = "https://app.smeditaly.it";
    }

    if (success) {
        return (
            <div className="tab user-info">
                <div className="title">
                    <h5 className="text_h5">Grazie per aver confermato la Privacy Policy</h5>
                    <p className="text_color_secondary">Ti ringraziamo per aver accettato la Privacy Policy di SMED. Ora puoi anche chiudere questa pagina web.</p>

                    <div className="column" style={{ marginTop: "32px" }}>
                        <Button onClick={handleToSMEDNavigation}>Vai al Portale SMED</Button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="tab user-info">
            <div className="title">
                <h5 className="text_h5">Inserisci il codice di verifica</h5>
                <p className="text_color_secondary">Inserisci il codice di verifica OTP che hai ricevuto tramite SMS al numero {phone}.</p>
                <p className="text_color_secondary">Tempo rimanente: {formatTime(remainingTime)}</p>
            </div>
            <OneTimePassword ref={otpRef} codeLength={6} />
            <div className="column" style={{ marginTop: "auto" }}>
                <Button onClick={handleAcceptTerms}>Verifica</Button>
                <Button onClick={handleChangeData}>Modifica i tuoi dati</Button>
            </div>
        </div>
    );
}

export default AcceptTerms;